<template lang="pug">
.container.mt-28.mb-28.px-10
  h1.mb-3.text-2xl.font-bold.text-blue-600.text-center {{ $t('homePage.comments_title_1') }}
  h2.mb-16.text-4xl.font-black.text-gray-700.text-center {{ $t('homePage.comments_title_2') }}
  .flex.w-full.relative
    .buttons.prev
      .bg-green-400.cursor-pointer.rounded.w-8.h-8.flex.justify-center.items-center.text-white(
        @click="$refs.buttonPrev.click()"
      )
        BaseCustomIcon(nameIcon="mdiChevronLeft", :size="28")
    .buttons.next
      .bg-green-400.cursor-pointer.rounded.w-8.h-8.flex.justify-center.items-center.text-white(
        @click="$refs.buttonNext.click()"
      ) 
        BaseCustomIcon(nameIcon="mdiChevronRight", :size="28")
    swiper(
      :slides-per-view="1",
      :space-between="0",
      :grabCursor="false",
      :loop="true",
      :loopAdditionalSlides="5",
      ref="mySwiperRef",
      :navigation="navigation",
      :breakpoints="{ 768: { slidesPerView: 2 }, 1024: { slidesPerView: 3 } }"
    )
      swiper-slide(v-for="(comment, index) in listComments" :key="index")
        cardComment(
          :image="`review_${index + 1}.png`",
          :name="comment.name",
          :position="comment.work",
          :text="comment.description"
        )      
      .swiper-button-prev(ref="buttonPrev", slot="button-prev")
      .swiper-button-next(ref="buttonNext", slot="button-next")
</template>

<script>
import cardComment from "./sectionComments.cardComment";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Navigation, Pagination]);
// Import Swiper styles
import "swiper/swiper.scss";

export default {
  name: "sectionComments",
  components: {
    cardComment,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      commentsList: {}
    };
  },
  computed: {
    listComments() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).homePage.comments_list;
    }
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  padding: 0 0px;
}

.buttons {
  position: absolute;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev {
  left: -0.5rem;
}

.next {
  right: -0.5rem;
}
</style>
