<template lang="pug">
.py-5.px-8.rounded-3xl.overflow-hidden.h-full.mx-3.border
  .flex.flex-col.h-full.justify-between
    .flex
      img.w-20.h-20.mr-5.mb-3.rounded-full(:src="`/theme/persons/${image}`")
      .font-bold.text-xl.mt-3.text-gray-500 {{ name }}
    .text-gray-500.font-normal.text-sm.leading-7 “{{ text }}”
    .text-blue-800.font-normal.text-xs.mt-5.self-end {{ position }}
</template>

<script>
export default {
  name: "cardComment",
  props: {
    image: String,
    name: String,
    text: String,
    position: String,
  },
};
</script>

<style lang="scss" scoped></style>
